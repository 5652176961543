<template>
  <div class="g-input">
    <ion-datetime
      display-format="MMM DD YYYY"
      cancelText="Cancelar"
      doneText="Ok"
      placeholder="DD/MM/YYYY"
      :monthShortNames="monthShortNames"
      :min="min"
      :max="max"
      :value="value"
      @ionChange="change($event)"
    ></ion-datetime>
  </div>
</template>
<script>
import { IonDatetime } from "@ionic/vue";
import moment from 'moment';

export default {
  name: "date-time",
  components: {
    IonDatetime,
  },
  props: {
    value: {
      type: String,
      default() {
        return '';
      }
    }
  },
  setup() {
    const min = moment().subtract(70, 'years').format('YYYY-MM-DD');
    const max = moment().subtract(18, 'years').format('YYYY-MM-DD');
    const monthShortNames = moment.monthsShort().map(month => month.charAt(0).toUpperCase() + month.slice(1));
    
    return {
      min,
      max,
      monthShortNames
    };
  },
  methods: {
    change(event) {
      this.$emit('onChange', event.detail.value);
    }
  },
};
</script>
<style lang="scss" scoped>
    ion-datetime {
        padding: 0;
        color: #757575 !important;
    }
</style>
